<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-4">
          <a-select
            id="typeJournal"
            show-search
            :placeholder="$t('comptabilite.typeRecette')"
            option-filter-prop="children"
            style="width: 100%"
            :filter-option="filterOption"
            @change="
              (val) => {
                handleFilterChange('recette', val);
              }
            "
            default-value="all"
          >
            <a-select-option value="all">{{
              $t("recette.tous")
            }}</a-select-option>
            <a-select-option
              v-for="(item, key) in recipetTypes"
              :key="key"
              :value="key"
            >
              {{
                item == "espéce"
                  ? $t("recette.espece")
                  : item == "chèque"
                  ? $t("recette.cheque")
                  : item == "Chèque et espéce"
                  ? $t("recette.chequeEtEspece")
                  : item
              }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-md-8">
          <a-date-picker
            style="width: 100%"
            :placeholder="$t('recette.selectionnerDate')"
            format="DD/MM/YYYY"
            @change="
              (val) => {
                handleFilterChange('date', val);
              }
            "
          />
        </div>
      </div>
      <div class="row justify-content-end mt-3">
        <div class="col-4 col-sm-3">
          <json-csv
            class="ant-btn"
            style="width: 100%"
            :fields="fields"
            :labels="labels"
            :data="csvTable"
            :name="'etatDesRecettes.csv'"
            ><a-icon
              class="anticon mt-2"
              style="color: green"
              type="file-excel"
            /><span>CSV</span></json-csv
          >
        </div>
        <div class="col-4 col-sm-3">
          <a-button type="primary" style="width: 100%" @click="imprimer"
            ><a-icon type="printer" />{{ $t("action.imprimer") }}</a-button
          >
        </div>
      </div>
    </div>
    <div class="card-body">
      <a-table
        @change="tableChanged"
        rowKey="_id"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :loading="tableLoading"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="date" slot-scope="text">
          {{ moment(text).format("DD/MM/YYYY") }}
        </template>
        <div slot="tags" slot-scope="text, record">
          <div class="d-block">
            <span v-for="item in record.tags" :key="item">
              <a-tag color="blue" v-if="item != 'all'">
                {{
                  recipetTypes[item] == "espéce"
                    ? $t("recette.espece")
                    : recipetTypes[item] == "chèque"
                    ? $t("recette.cheque")
                    : recipetTypes[item] == "Chèque et espéce"
                    ? $t("recette.chequeEtEspece")
                    : recipetTypes[item]
                }}
              </a-tag>
            </span>
            <a-tag
              color="orange"
              v-if="record.cancellation && record.cancellation.type == 'Avoir'"
            >
              {{ $t("paiement.avoir") }}
            </a-tag>
          </div>
        </div>
        <template slot="modePaiement" slot-scope="text, record">
          <a-button
            icon="search"
            type="primary"
            block
            v-if="record.cheque"
            @click="chequeDetailsModal(record)"
          >
            {{
              text == "espéce"
                ? $t("recette.espece")
                : text == "chèque"
                ? $t("recette.cheque")
                : text == "Chèque et espéce"
                ? $t("recette.chequeEtEspece")
                : text
            }}
          </a-button>
          <div v-else>
            {{
              text == "espéce"
                ? $t("recette.espece")
                : text == "chèque"
                ? $t("recette.cheque")
                : text == "Chèque et espéce"
                ? $t("recette.chequeEtEspece")
                : text
            }}
          </div>
        </template>
        <template slot="cheque" slot-scope="text">
          <div v-if="text">
            {{ text }}
          </div>
          <div v-else>--</div>
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
    </div>
    <a-modal
      v-model="chequeVisible"
      :title="$t('recette.detailsCheque')"
      @ok="handleOk"
      :cancelText="$t('action.annuler')"
      :okText="$t('recette.ok')"
    >
      <div v-if="activeCheque.montantCheque || activeCheque.montantEspece">
        <a-descriptions>
          <a-descriptions-item span="2">
            <template v-slot:label>
              <span class="ant-descriptions-item-label"
                >{{ $t("recette.montantCheque") }} :</span
              >
            </template>
            {{ activeCheque.montantCheque }}
          </a-descriptions-item>
          <a-descriptions-item span="3">
            <template v-slot:label>
              <span class="ant-descriptions-item-label"
                >{{ $t("recette.montantEspece") }} :</span
              >
            </template>
            {{ activeCheque.montantEspece }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
      </div>
      <a-descriptions>
        <a-descriptions-item span="2">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.numero")
            }}</span>
          </template>
          {{ activeCheque.num }}
        </a-descriptions-item>
        <a-descriptions-item span="3">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.titulaire")
            }}</span>
          </template>
          {{ activeCheque.titulaire }}
        </a-descriptions-item>
        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.banque")
            }}</span>
          </template>
          <a-tag color="blue">
            {{ activeCheque.bank }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.dateEcheance")
            }}</span>
          </template>
          {{ moment(activeCheque.dateEchance).format("DD/MM/YYYY") }}
        </a-descriptions-item>
      </a-descriptions>
      <a-alert
        :message="moment(activeCheque.dateEchance).fromNow()"
        type="info"
        :close-text="$t('recette.fermer')"
      />
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
const recipetTypes = {};
var datePicker = "";
/*
data : {
    nomClient,
    enfant,
    date,
    montant
    tags:Array of keysOf(recipeTypes or/and userDefinedRecipes)
}
*/
const moneyQuery = {
  $or: [{ status: { $ne: "cancelled" } }, { "cancellation.type": "Avoir" }],
};

export default {
  components: {
    JsonCsv,
  },
  async created() {
    this.tableLoading = true;
    await apiClient
      .post("/parents/filter", {
        query: {},
        aggregation: {
          $project: {
            father: 1,
            mother: 1,
          },
        },
      })
      .then((res) => (this.parents = res.data))
      .catch();
    await apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" }, //schoolarYear: this.settings.activeSchoolarYear
      })
      .then((res) => {
        res.data.map((recipe) => (this.recipetTypes[recipe._id] = recipe.name));
      })
      .catch();
    await apiClient
      .post("/money/activityreceipt/filter", {
        query: moneyQuery,
        aggregation: [
          {
            $lookup: {
              from: "activityfees",
              localField: "activityFees",
              foreignField: "_id",
              as: "activityfees",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "activityfees.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "activities",
              localField: "activityfees.activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              onBillReduction: 1,
              date: 1,
              months: 1,
              onInscription: 1,
              student: {
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              activity: {
                _id: 1,
                designation: 1,
                monthly: 1,
                inscription: 1,
              },
              activityfees: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              cheque: { $arrayElemAt: ["$cheque", 0] },
              receiptType: { name: 1, _id: 1 },
              montantCheque: 1,
              montantEspece: 1,
              cancellation: 1,
            },
          },
        ],
      })
      .then((res) => {
        res.data.map((elem) => {
          this.data.push(
            this.activityFormater({
              ...elem,
              activity: elem.activity[0],
              activityfees: elem.activityfees[0],
              receiptType: elem.receiptType[0],
              student: elem.student[0],
            })
          );
          this.rowData = this.data;
        });
      })
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    await apiClient
      .post("/money/activitybill/filter", {
        query: moneyQuery,
        aggregation: [
          {
            $lookup: {
              from: "activityfees",
              localField: "activityFees",
              foreignField: "_id",
              as: "activityfees",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "activityfees.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "activities",
              localField: "activityfees.activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              onBillReduction: 1,
              date: 1,
              months: 1,
              onInscription: 1,
              student: {
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              activity: {
                _id: 1,
                designation: 1,
                monthly: 1,
                inscription: 1,
              },
              activityfees: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              cheque: { $arrayElemAt: ["$cheque", 0] },
              receiptType: { name: 1, _id: 1 },
              montantCheque: 1,
              montantEspece: 1,
              cancellation: 1,
            },
          },
        ],
      })
      .then((res) => {
        res.data.map((elem) => {
          this.data.push(
            this.activityFormater(
              {
                ...elem,
                activity: elem.activity[0],
                activityfees: elem.activityfees[0],
                receiptType: elem.receiptType[0],
                student: elem.student[0],
              },
              true
            )
          );
          this.rowData = this.data;
        });
      })
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    await apiClient
      .post("/money/classroomreceipt/filter", {
        query: moneyQuery,
        aggregation: [
          {
            $lookup: {
              from: "classroompayments",
              localField: "classRoomPayment",
              foreignField: "_id",
              as: "classRoomPayment",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "classRoomPayment.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoomPayment.classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              onBillReduction: 1,
              date: 1,
              months: 1,
              onInscription: 1,
              student: {
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              classRoom: {
                _id: 1,
                name: 1,
                monthly: 1,
                inscription: 1,
              },
              classRoomPayment: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              cheque: { $arrayElemAt: ["$cheque", 0] },
              receiptType: { name: 1, _id: 1 },
              montantCheque: 1,
              montantEspece: 1,
              cancellation: 1,
            },
          },
        ],
      })
      .then((res) => {
        res.data.map((elem) => {
          if (elem.classRoomPayment[0]) {
            this.data.push(
              this.classRoomPFormater({
                ...elem,
                classRoom: elem.classRoom[0],
                classRoomPayment: elem.classRoomPayment[0],
                receiptType: elem.receiptType[0],
                student: elem.student[0],
              })
            );
          }
        });
        this.rowData = this.data;
      })
      .catch((e) => {
        this.$message.warning(this.$t("warning.connectionProblem"));
        console.log(e);
      });
    await apiClient
      .post("/money/classroombill/filter", {
        query: moneyQuery,
        aggregation: [
          {
            $lookup: {
              from: "classroompayments",
              localField: "classRoomPayment",
              foreignField: "_id",
              as: "classRoomPayment",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "classRoomPayment.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoomPayment.classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              onBillReduction: 1,
              date: 1,
              months: 1,
              onInscription: 1,
              student: {
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              classRoom: {
                _id: 1,
                name: 1,
                monthly: 1,
                inscription: 1,
              },
              classRoomPayment: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              cheque: { $arrayElemAt: ["$cheque", 0] },
              receiptType: { name: 1, _id: 1 },
              montantCheque: 1,
              montantEspece: 1,
              cancellation: 1,
            },
          },
        ],
      })
      .then((res) => {
        res.data.map((elem) => {
          try {
            if (elem.classRoomPayment[0]) {
              this.data.push(
                this.classRoomPFormater(
                  {
                    ...elem,
                    classRoom: elem.classRoom[0],
                    classRoomPayment: elem.classRoomPayment[0],
                    receiptType: elem.receiptType[0],
                    student: elem.student[0],
                  },
                  true
                )
              );
            }
          } catch {}

          this.rowData = this.data;
          this.tableLoading = false;
          this.filtredTable = this.data;
        });
      })
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    this.tableLoading = false;
    this.filtredTable = [...this.data];
  },
  computed: {
    ...mapState(["settings"]),
    csvTable() {
      const data = this.filtredTable.map((item) => {
        let returnValue = { ...item };
        let modePaiement = item.modePaiement;

        if (modePaiement == "Chèque et espéce") {
          returnValue.montantCheque = item.montantCheque;
          returnValue.montantEspece = item.montantEspece;
        } else if (modePaiement == "chèque") {
          returnValue.montantCheque = item.montantTTC;
        } else {
          returnValue.montantEspece = item.montantTTC;
        }
        returnValue.NumCheque = item.cheque ? item.cheque.num : "--";
        returnValue.date = moment(item.date).format("DD/MM/YYYY");
        return returnValue;
      });
      return data;
    },
  },
  data() {
    return {
      labels: {
        nomclient: "Nom client",
        typeReglement: "Type Reglement",
        modePaiement: "Mode paiement",
        montantCheque: "Montant chèque",
        montantEspece: "Montant espéce",
        montantHT: "Montant HT",
        montantTTC: "Montant TTC",
        date: "Date de paiement",
        NumCheque: "Numéro du chèque",
      },
      fields: [
        "nomClient",
        "typeReglement",
        "modePaiement",
        "montantCheque",
        "montantEspece",
        "montantHT",
        "montantTTC",
        "date",
        "NumCheque",
      ],
      recipetTypes,
      datePicker,
      data: [],
      rowData: [],
      cacheData: [],
      chequeVisible: false,
      activeCheque: {},
      parents: [],
      filters: {
        recette: false,
        recette_val: null,
        date: false,
        date_val: null,
      },
      columns: [
        {
          title: this.$t("recette.nom"),
          dataIndex: "nomClient",
          key: "nomClient",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nomClient",
          },
          onFilter: (value, record) =>
            record.nomClient.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeReglement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "modePaiement",
          key: "modePaiement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "modePaiement",
          },
          onFilter: (value, record) => {
            console.log(value, record);
            if (record.cheque) {
              return (
                record.modePaiement
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.num.toLowerCase().includes(value.toLowerCase()) ||
                record.cheque.bank
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.titulaire
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.dateEchance
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            } else {
              return;
              record.modePaiement.toLowerCase().includes(value.toLowerCase());
            }
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantHT"),
          dataIndex: "montantHT",
          key: "montantHT",
          sorter: (a, b) => a.montantHT - b.montantHT,
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "montantTTC",
          key: "montantTTC",
          sorter: (a, b) => a.montantTTC - b.montantTTC,
        },
        {
          //Mots clés
          title: "Tags",
          dataIndex: "tags",
          key: "tags",
          scopedSlots: {
            customRender: "tags",
          },
        },
        {
          title: this.$t("recette.datePaiement"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            record.date.includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.numCheque"),
          dataIndex: "cheque.num",
          key: "cheque",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "cheque",
          },
          onFilter: (value, record) =>
            record.cheque.num.includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      filtredTable: [],
      FISCAL: parseFloat(process.env.VUE_APP_FISCAL),
      TVA: parseFloat(process.env.VUE_APP_TVA),
    };
  },
  methods: {
    chequeDetailsModal(record) {
      let recordData = JSON.parse(JSON.stringify(record));

      this.activeCheque = recordData.cheque;
      this.activeCheque.montantCheque = recordData.montantCheque;
      this.activeCheque.montantEspece = recordData.montantEspece;
      this.chequeVisible = true;
    },
    handleOk(e) {
      this.chequeVisible = false;
    },
    moment,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },

    imprimer() {
      this.$gtag.event("Imp etatDesRecettes", {
        event_category: "Impression PDF",
        event_label: "comptabilite:etatDesRecettes",
        value: 1,
      });

      let typeJournal = document.getElementById("typeJournal").innerText;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      let filterText =
        this.datePicker !== "" ? " le " + this.datePicker : " Tous les mois";
      var titre = "Etat des recettes - " + typeJournal;
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(89, 70, filterText);
      doc.setFontSize(10);
      try {
        if (imgData) doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      doc.setFontSize(8);
      doc.setFillColor("#FFCCCC");
      doc.setDrawColor("#FFCCCC");
      doc.rect(15, 74, 4, 4, "FD");
      doc.text(22, 77, "Annulation");
      doc.setFillColor("#FFCC66");
      doc.setDrawColor("#FFCC66");
      doc.rect(40, 74, 4, 4, "FD");
      doc.text(47, 77, "Avoir");
      doc.setFontSize(12);
      var arr = [];
      const array = [];
      let sommeHT = 0;
      let sommeTTC = 0;
      let sommeEsp = 0;
      let sommeChq = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push({
          content: this.filtredTable[i].nomClient,
          styles:
            this.filtredTable[i].cancellation &&
            this.filtredTable[i].cancellation.type == "Avoir"
              ? { fillColor: [255, 234, 153] }
              : {},
        });

        arr.push({
          content:
            this.filtredTable[i].student.firstName +
            " " +
            this.filtredTable[i].student.lastName,
          styles:
            this.filtredTable[i].cancellation &&
            this.filtredTable[i].cancellation.type == "Avoir"
              ? { fillColor: [255, 234, 153] }
              : {},
        });

        arr.push({
          content: this.filtredTable[i].typeReglement,
          styles:
            this.filtredTable[i].cancellation &&
            this.filtredTable[i].cancellation.type == "Avoir"
              ? { fillColor: [255, 234, 153] }
              : {},
        });
        let modePaiement = this.filtredTable[i].modePaiement;
        arr.push({
          content: modePaiement,
          styles:
            this.filtredTable[i].cancellation &&
            this.filtredTable[i].cancellation.type == "Avoir"
              ? { fillColor: [255, 234, 153] }
              : {},
        });
        arr.push({
          content: this.filtredTable[i].cheque
            ? this.filtredTable[i].cheque.num
            : "--",
          styles:
            this.filtredTable[i].cancellation &&
            this.filtredTable[i].cancellation.type == "Avoir"
              ? { fillColor: [255, 234, 153] }
              : {},
        });

        if (modePaiement == "Chèque et espéce") {
          arr.push({
            content: Number(this.filtredTable[i].montantCheque),
            styles:
              this.filtredTable[i].cancellation &&
              this.filtredTable[i].cancellation.type == "Avoir"
                ? { fillColor: [255, 234, 153] }
                : {},
          });
          sommeChq += this.filtredTable[i].montantCheque
            ? parseFloat(this.filtredTable[i].montantCheque)
            : 0;
          arr.push({
            content: Number(this.filtredTable[i].montantEspece),
            styles:
              this.filtredTable[i].cancellation &&
              this.filtredTable[i].cancellation.type == "Avoir"
                ? { fillColor: [255, 234, 153] }
                : {},
          });
          sommeEsp += this.filtredTable[i].montantEspece
            ? parseFloat(this.filtredTable[i].montantEspece)
            : 0;
        } else if (modePaiement == "chèque") {
          arr.push({
            content: this.filtredTable[i].montantTTC.toFixed(2),
            styles:
              this.filtredTable[i].cancellation &&
              this.filtredTable[i].cancellation.type == "Avoir"
                ? { fillColor: [255, 234, 153] }
                : {},
          });
          sommeChq += parseFloat(this.filtredTable[i].montantTTC);
          arr.push({
            content: "--",
            styles:
              this.filtredTable[i].cancellation &&
              this.filtredTable[i].cancellation.type == "Avoir"
                ? { fillColor: [255, 234, 153] }
                : {},
          });
        } else {
          arr.push({
            content: "--",
            styles:
              this.filtredTable[i].cancellation &&
              this.filtredTable[i].cancellation.type == "Avoir"
                ? { fillColor: [255, 234, 153] }
                : {},
          });
          arr.push({
            content: this.filtredTable[i].montantTTC.toFixed(2),
            styles:
              this.filtredTable[i].cancellation &&
              this.filtredTable[i].cancellation.type == "Avoir"
                ? { fillColor: [255, 234, 153] }
                : {},
          });
          sommeEsp += parseFloat(this.filtredTable[i].montantTTC);
        }

        arr.push({
          content: this.filtredTable[i].montantTTC.toFixed(2),
          styles:
            this.filtredTable[i].cancellation &&
            this.filtredTable[i].cancellation.type == "Avoir"
              ? { fillColor: [255, 234, 153] }
              : {},
        });
        sommeTTC += parseFloat(this.filtredTable[i].montantTTC);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        styles: { font: "Amiri" },
        head: [
          [
            "Parent",
            "Élève",
            "Type réglement",
            "Mode paiement",
            "Numéro du chèque",
            "Montant chèque",
            "Montant espece",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          [
            { colSpan: 5, content: "Total", styles: { halign: "center" } },
            sommeChq.toFixed(2),
            sommeEsp.toFixed(2),
            sommeTTC.toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Etat des recettes.pdf");
    },
    handleChangeTypeJournal(tag) {
      this.data = this.rowData.filter((elem) => elem.tags.includes(tag));
    },
    handlePanelChange(value) {
      this.datePicker = moment(value).format("DD/MM/YYYY");
      if (!moment(value).isValid()) this.data = [...this.rowData];
      else
        this.data = this.rowData.filter(
          (elem) =>
            moment(elem.date).format("DD/MM/YYYY") ==
            moment(value).format("DD/MM/YYYY")
        );
    },
    handleFilterChange(on, value) {
      const other = on == "recette" ? "date" : "recette";
      let newData = [];
      this.filters[on] = true;
      this.filters[`${on}_val`] = value;
      if (on == "recette") {
        newData = this.rowData.filter((elem) => elem.tags.includes(value));
      } else {
        this.datePicker = moment(value).format("DD/MM/YYYY");
        if (!moment(value).isValid()) newData = [...this.rowData];
        else
          newData = this.rowData.filter(
            (elem) =>
              moment(elem.date).format("DD/MM/YYYY") ==
              moment(value).format("DD/MM/YYYY")
          );
      }
      if (this.filters[other]) {
        if (other == "recette") {
          newData = newData.filter((elem) =>
            elem.tags.includes(this.filters[`${other}_val`])
          );
        } else {
          if (!moment(this.filters[`${other}_val`]).isValid())
            newData = [...newData];
          else {
            newData = newData.filter(
              (elem) =>
                moment(elem.date).format("DD/MM/YYYY") ==
                moment(this.filters[`${other}_val`]).format("DD/MM/YYYY")
            );
          }
        }
      }
      this.data = newData;
      this.filtredTable = newData;
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    frMonths(s) {
      const months = {};
      const monthsArr = moment.months();
      for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
      return months[parseInt(s.split("-")[1])];
    },
    classRoomPFormater(paymentData, bill = false) {
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      const reduction = {
        inscription: paymentData.classRoomPayment.reduction.inscription,
        monthly: paymentData.classRoomPayment.reduction.monthly,
      };
      let money = 0;

      if (paymentData.onInscription) {
        const inscriptionFees = parseFloat(paymentData.classRoom.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.classRoom.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      money = money - (money * onBillReduction) / 100;
      let moneyTTC = money + (bill ? this.FISCAL : 0);
      const parent = this.parents.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let data = {
        url: bill ? "/bills/classroom/" + id : "/receipt/classroom/" + id,
        _id: id,
        nomClient:
          parent.mother.firstName +
          " " +
          parent.mother.lastName +
          " ET " +
          parent.father.firstName +
          " " +
          parent.father.lastName,
        typeReglement: "Scolarité/" + paymentData.classRoom.name,
        modePaiement: paymentData.receiptType.name,
        montantHT: money - (bill ? money * this.TVA : 0),
        montantTTC: moneyTTC,
        date: paymentData.date,
        classRoomPayment: paymentData.classRoomPayment,
        classRoom: paymentData.classRoom,
        student: paymentData.student,
        bill: bill,
        cheque: paymentData.cheque ? paymentData.cheque : null,
        montantEspece: paymentData.montantEspece
          ? paymentData.montantEspece
          : null,
        montantCheque: paymentData.montantCheque
          ? paymentData.montantCheque
          : null,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
        tags: ["all", paymentData.receiptType._id],
        cancellation: paymentData.cancellation,
      };
      return data;
    },
    activityFormater(paymentData, bill = false) {
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      const reduction = {
        inscription: paymentData.activityfees.reduction.inscription,
        monthly: paymentData.activityfees.reduction.monthly,
      };
      let money = 0;
      if (paymentData.onInscription) {
        const inscriptionFees = parseFloat(paymentData.activity.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.activity.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      money = money - (money * onBillReduction) / 100;
      let moneyTTC = money + (bill ? this.FISCAL : 0);
      const parent = this.parents.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let data = {
        url: bill ? "/bills/activity/" + id : "/receipt/activity/" + id,
        _id: id,
        nomClient:
          parent.mother.firstName +
          " " +
          parent.mother.lastName +
          " ET " +
          parent.father.firstName +
          " " +
          parent.father.lastName,
        typeReglement: "Activité/" + paymentData.activity.designation,
        modePaiement: paymentData.receiptType.name,
        montantHT: money - (bill ? money * this.TVA : 0),
        date: paymentData.date,
        activity: paymentData.activity,
        student: paymentData.student,
        montantTTC: moneyTTC,
        activityfees: paymentData.activityfees,
        bill: bill,
        cheque: paymentData.cheque ? paymentData.cheque : null,
        montantEspece: paymentData.montantEspece
          ? paymentData.montantEspece
          : null,
        montantCheque: paymentData.montantCheque
          ? paymentData.montantCheque
          : null,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
        tags: ["all", paymentData.receiptType._id],
        cancellation: paymentData.cancellation,
      };
      return data;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
